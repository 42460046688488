import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  baseUrl: string;
  constructor(private http: HttpClient, private router: Router) {
    this.baseUrl = `${environment.apiURL}`;

  }

  login(model: any) {
    return this.http.post(this.baseUrl + "login", model).pipe(
      map((response: any) => {
          const user = response;
          if (user.token) {
            localStorage.setItem("token", user.token);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
          }
        },
        error => {
          
        }
      )
    );
  }

  loggedIn() {
    if (localStorage.getItem("token") != null) {
      const token = localStorage.getItem("token");
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }
}
