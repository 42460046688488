import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appMatchValidators]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: MismatchDirective,
        multi: true
    }]
})
export class MismatchDirective {
    @Input() appMatchValidators: string;

    constructor() { }

    validate(v: AbstractControl): ValidationErrors | null {
        if ( v.value === null || v.value.length === 0) {
            return null;
        }
        const controlToBeCompared = v.root.get(this.appMatchValidators);
        if (controlToBeCompared) {
            const subscription: Subscription = controlToBeCompared.valueChanges.subscribe(() => {
                v.updateValueAndValidity();
                subscription.unsubscribe();
            });
        }

        return controlToBeCompared && controlToBeCompared.value !== v.value ? {'appMatchValidators': true } : null;

    }

}
