import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Sexos } from "app/shared/models/aplicacao/sexos";
import { Observable } from "rxjs";
import { EstadosCivis } from "app/shared/models/aplicacao/estadoscivis";
import { catchError } from "rxjs/operators";
import { Paises } from "app/shared/models/aplicacao/paises";
import { Municipios } from "app/shared/models/aplicacao/municipios";
import { Zonas } from "app/shared/models/aplicacao/zonas";
import { Provincias } from "app/shared/models/aplicacao/provincias";
import { CategoriasContactos } from "app/shared/models/aplicacao/categoriascontactos";
import { TiposConctatos } from "app/shared/models/aplicacao/tiposcontactos";
import { Aplicacao } from "app/shared/models/aplicacao/aplicacao";
import { Moedas } from "app/shared/models/aplicacao/moedas";
import { Idiomas } from "app/shared/models/aplicacao/idiomas";
import { Menus } from "app/shared/models/aplicacao/menus";
import { TiposDocumentos } from "app/shared/models/aplicacao/tiposdocumentos";
import { environment } from "environments/environment";
import { OpccaoInsercao } from "app/shared/models/aplicacao/opccaoInsercao";
import { Permissoes } from "app/shared/models/aplicacao/permissoes";
import { Unidades } from "app/shared/models/aplicacao/unidades";
import { ProeficienciaIdiomas } from "app/shared/models/aplicacao/proeficienciaidiomas";

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  })
};

@Injectable({
  providedIn: "root",
})
export class AplicacaoService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiURL}`;
  }

  getAplicacao(): Observable<Aplicacao> {
    return this.http
      .get(this.baseUrl + "aplicacao", httpOptions)
      .pipe(catchError(null));
  }

  getMenus(): Observable<Menus[]> {
    return this.http
      .get(this.baseUrl + "menus/1", httpOptions)
      .pipe(catchError(null));
  }

  getMoedas(): Observable<Moedas[]> {
    return this.http
      .get(this.baseUrl + "moedas", httpOptions)
      .pipe(catchError(null));
  }

  getIdiomas(): Observable<Idiomas[]> {
    return this.http
      .get(this.baseUrl + "idiomas", httpOptions)
      .pipe(catchError(null));
  }

  getProeficiencia(): Observable<ProeficienciaIdiomas[]> {
    return this.http
      .get(this.baseUrl + "aplicacao/proeficienciaidiomas", httpOptions)
      .pipe(catchError(null));
  }

  getPermissoes(): Observable<Permissoes[]> {
    return this.http
      .get(this.baseUrl + "aplicacao/permissoes", httpOptions)
      .pipe(catchError(null));
  }

  getPaises(ordem: string): Observable<Paises[]> {
    return this.http
      .get(this.baseUrl + "paisesordenados/" + ordem, httpOptions)
      .pipe(catchError(null));
  }

  getPais(id: number): Observable<Paises> {
    return this.http
      .get(this.baseUrl + "paises/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getProvincias(idPais: number): Observable<Provincias[]> {
    return this.http
      .get(this.baseUrl + "provincias/" + idPais, httpOptions)
      .pipe(catchError(null));
  }

  getMunicipios(idPais: number, idProvincia: number): Observable<Municipios[]> {
    return this.http
      .get(
        this.baseUrl + "municipios/" + idPais + "/" + idProvincia,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getSexos(): Observable<Sexos[]> {
    return this.http
      .get(this.baseUrl + "sexos", httpOptions)
      .pipe(catchError(null));
  }

  getEstadosCivis(): Observable<EstadosCivis[]> {
    return this.http
      .get(this.baseUrl + "estadoscivis", httpOptions)
      .pipe(catchError(null));
  }

  getCategoriasContactos(): Observable<CategoriasContactos[]> {
    return this.http
      .get(this.baseUrl + "categoriascontactos", httpOptions)
      .pipe(catchError(null));
  }

  getTiposContactos(): Observable<TiposConctatos[]> {
    return this.http
      .get(this.baseUrl + "tiposcontactos", httpOptions)
      .pipe(catchError(null));
  }

  getOpcoesInsercao(): Observable<OpccaoInsercao[]> {
    return this.http
      .get(this.baseUrl + "opcoesinsercao", httpOptions)
      .pipe(catchError(null));
  }

  getUnidades(idTipo: number): Observable<Unidades[]> {
    return this.http
      .get(this.baseUrl + "aplicacao/unidadesmedidas/" + idTipo, httpOptions)
      .pipe(catchError(null));
  }
}
