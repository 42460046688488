import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sucesso-editar',
  templateUrl: './sucesso-editar.component.html',
  styleUrls: ['./sucesso-editar.component.scss']
})
export class SucessoEditarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
