// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiURL: "https://appV8.kiami.ao/apl/v1/",
  apiURLFinancas: "https://appV8.kiami.ao/fin/v1/",
  apiURLProcessos: "https://appV8.kiami.ao/pro/api/v1.0/processos/",
  apiURLRH: "https://appV8.kiami.ao/rhu/api/v1.0/recursoshumanos/",
  //apiURLRH: "http://localhost:5000/api/v1.0/recursoshumanos/",
  //apiURL: "http://localhost:5000/v1/",
  //apiURL: "https://localhost:44332/v1/",
  enableDebug: true,
};
