import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SucessoEliminarComponent} from './sucesso-eliminar/sucesso-eliminar.component';
import {SucessoCriarComponent} from './sucesso-criar/sucesso-criar.component';
import {SucessoEditarComponent} from './sucesso-editar/sucesso-editar.component';
import {ErroCriarComponent} from './erro-criar/erro-criar.component';
import {ErroEditarComponent} from './erro-editar/erro-editar.component';
import {ErroEliminarComponent} from './erro-eliminar/erro-eliminar.component';
import {SharedMaterialModule} from '../../../shared-material.module';

const components = [
  SucessoCriarComponent,
  SucessoEliminarComponent,
  SucessoEditarComponent,
  ErroCriarComponent,
  ErroEditarComponent,
  ErroEliminarComponent,

];

@NgModule({
  declarations: components,
  imports: [CommonModule, SharedMaterialModule],
  providers: components,
  exports: components,
  entryComponents: components
})
export class SharedSnackbarModule { }
