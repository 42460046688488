import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AplicacaoService } from './aplicacao-service/aplicacao.service';
import { Menus } from '../models/aplicacao/menus';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  code?: string; // Menu code
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

listaCodigosMenus: [];

@Injectable()
export class NavigationService {
  public iconMenu: IMenuItem[] = [
    {
      name: "HOME",
      type: "icon",
      tooltip: "Home",
      icon: "home",
      state: "home",
    },

    {
      name: "TOUR",
      type: "icon",
      tooltip: "Tour",
      icon: "flight_takeoff",
      state: "tour",
    },

    { type: "separator", name: "Main Items" },

    {
      name: "DASHBOARD",
      code: "DASHBO",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard/default",
    },

    // Menu Recursos Humanos

    {
      name: "Recursos Humanos",
      code: "RECHUM",
      type: "dropDown",
      tooltip: "Material",
      icon: "group_add",
      state: "rh",
      sub: [
        {
          name: "Colaboradores",
          state: "colaboradores",
        },
        {
          name: "Estrutura Organizativa",
          state: "estrutura-organizativa",
        },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            {
              name: "Tipos de informação",
              state: "configuracoes/tipos-informacao",
            },
            {
              name: "Tipos de colaboração",
              state: "configuracoes/tipos-colaboracao",
            },
            {
              name: "Estrutura Organizativa",
              state: "configuracoes/estrutura-organizativa",
            },
            { name: "Cargos", state: "configuracoes/cargos" },
            { name: "Funções", state: "configuracoes/funcoes" },
            { name: "Posições", state: "configuracoes/posicoes" },
          ],
        },
      ],
    },

    // Fim - Menu RH

    // Menu Clientes
    {
      name: "Clientes",
      code: "CLIENT",
      type: "dropDown",
      tooltip: "Clientes",
      icon: "group_add",
      state: "clientes",
      sub: [
        {
          name: "Clientes",
          state: "lista",
        },
        {
          name: "Acessos",
          state: "lista/acessos",
        },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            { name: "Estados", state: "configuracoes/estados" },
            { name: "Tipos", state: "configuracoes/tipos" },
            { name: "Tipos de Arquivos", state: "configuracoes/arquivos" },
            { name: "Tipo de Localização", state: "configuracoes/localizacao" },
          ],
        },
      ],
    },
    // Fim - Clientes

    // Menu Artigos

    {
      name: "Artigos",
      code: "ARTIGO",
      type: "dropDown",
      tooltip: "Artigos",
      icon: "local_mall",
      state: "artigos",
      sub: [
        {
          name: "Artigos",
          state: "lista",
        },

        {
          name: "Inventário",
          type: "dropDown",
          sub: [
            { name: "Inventário", state: "inventario/lista" },
            { name: "Armazéns", state: "inventario/armazens" },
          ],
        },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            { name: "Categorias", state: "configuracoes/categoria" },
            { name: "Tipos", state: "configuracoes/tipos" },
            { name: "Estados", state: "configuracoes/estados" },
            { name: "Armazéns", state: "configuracoes/armazens" },
          ],
        },
      ],
    },
    // Fim - Artigos

    // Menu Clientes
    {
      name: "Recrutamento",
      code: "RECRUT",
      type: "dropDown",
      tooltip: "Recrutamento",
      icon: "group_add",
      state: "recrutamento",
      sub: [
        {
          name: "Vagas",
          state: "vagas",
        },
        {
          name: "Candidaturas",
          state: "candidaturas/lista",
        },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            { name: "Áreas", state: "configuracoes/areas" },
            { name: "Funções", state: "configuracoes/funcoes" },
            { name: "Cargos", state: "configuracoes/cargos" },
            { name: "Graus Académicos", state: "configuracoes/grausacademico" },
          ],
        },
      ],
    },

    // Menu	Processos

    {
      name: "Processos",
      code: "PROCES",
      type: "dropDown",
      tooltip: "Material",
      icon: "swap_calls",
      state: "processos",
      sub: [
        //{ name: "Processos", state: "lista" },
        { name: "Serviços e Tarefas", state: "servicos-tarefas" },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            { name: "Tipos", state: "configuracoes/tipos" },
            { name: "Tarefas", state: "configuracoes/tarefas" },
            { name: "Estados", state: "configuracoes/estados" },
          ],
        },
      ],
    },
    // Fim -	Processos

    // Menu Equipamentos

    {
      name: "Equipamentos",
      code: "EQUIPA",
      type: "dropDown",
      tooltip: "Equipamentos",
      icon: "build",
      state: "equipamentos",
      sub: [
        { name: "Equipamentos", state: "lista" },
        { name: "Manutenções", state: "manutencoes" },
        { name: "Arrumação", state: "arrumacao" },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            {
              name: "Tipos de Equipamentos",
              state: "configuracoes/tipos-equipamentos",
            },
            { name: "Arrumação", state: "configuracoes/arrumacao" },
            {
              name: "Tipos de Arrumação",
              state: "configuracoes/tipos-arrumacao",
            },
            { name: "Categorias", state: "configuracoes/categorias" },
            { name: "Marcas", state: "configuracoes/marcas" },
            { name: "Modelos", state: "configuracoes/modelos" },
          ],
        },
      ],
    },
    // Fim - Equipamentos
    // Manutencao

    {
      name: "Manutenção",
      code: "MANUTE",
      type: "dropDown",
      tooltip: "Manutenção",
      icon: "directions_car",
      state: "manutencao",
      sub: [
        {
          name: "Manutenções",
          state: "lista",
        },

        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            { name: "Tipos de Manutenção", state: "configuracoes/tipos" },
            { name: "Estados da Manutenção", state: "configuracoes/estados" },
          ],
        },
      ],
    },
    // Fim - Manutencao
    // Menu	Facturação
    {
      name: "Facturação",
      code: "FACTUR",
      type: "dropDown",
      tooltip: "Facturação",
      icon: "store_mall_directory",
      state: "facturacao",
      sub: [
        { name: "Facturação", state: "facturacao" },
        {
          name: "Documentos",
          type: "dropDown",
          sub: [
            { name: "Cabeçalho", state: "consultar/cabecalho" },
            { name: "Item", state: "consultar/item" },
          ],
        },
        /* {
          name: "Consultar",
          type: "dropDown",
          sub: [
            { name: "Cabeçalho", state: "consultar/cabecalho" },
            { name: "Item", state: "consultar/item" },
          ],
        },
        {
          name: "Referências Multicaixa",
          type: "dropDown",
          sub: [
            {
              name: "Gerar Referência",
              state: "referencia-multicaixa/gerar-referencia",
            },
          ],
        },*/
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            {
              name: "Tipos de Documentos",
              state: "configuracoes/tipos-documento",
            },
            {
              name: "Estado Documentos",
              state: "configuracoes/estados-documento",
            },
          ],
        },
      ],
    },
    // Fim -	Vendas
    // Menu	Eventos

    {
      name: "Eventos",
      type: "dropDown",
      code: "EVENTO",
      tooltip: "Material",
      icon: "perm_camera_mic",
      state: "eventos",
      sub: [
        { name: "Eventos", state: "lista" },
        { name: "Inscritos", state: "inscritos" },
        { name: "Cursos", state: "cursos" },
        { name: "Módulos", state: "modulos" },
        { name: "Turmas", state: "turmas" },
        {
          name: "Certificados",
          type: "dropDown",
          sub: [
            { name: "Emitidos", state: "certificados/emitidos" },
            { name: "Por Emitir", state: "certificados/por-emitir" },
          ],
        },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            { name: "Tipos de Eventos", state: "configuracoes/tipos" },
            { name: "Estados dos Eventos", state: "configuracoes/estados" },
          ],
        },
      ],
    },
    // Fim -	Eventos

    // Menu	•	Tesouraria
    {
      name: "Tesouraria",
      code: "TESOUR",
      type: "dropDown",
      tooltip: "Material",
      icon: "attach_money",
      state: "tesouraria",
      sub: [
        { name: "Recebimentos", state: "recebimentos" },
        {
          name: "Consultar",
          type: "dropDown",
          sub: [
            { name: "Cabeçalho", state: "consultar/cabecalho" },
            { name: "Item", state: "consultar/item" },
          ],
        },
        {
          name: "Referências Multicaixa",
          type: "dropDown",
          sub: [
            { name: "Consultar", state: "referencia-multicaixa/consultar" },
          ],
        },
        {
          name: "Bancos e Caixas",
          type: "dropDown",
          sub: [
            {
              name: "Contas Bancárias",
              state: "bancos-caixas/contas-bancarias",
            },
            { name: "Caixas", state: "bancos-caixas/caixas" },
          ],
        },
        {
          name: "Configurações",
          type: "dropDown",
          sub: [
            {
              name: "Tipos de Documentos",
              state: "configuracoes/tipos-documentos",
            },
            {
              name: "Estado Documentos",
              state: "configuracoes/estados-documentos",
            },
            { name: "Bancos e Caixas", state: "configuracoes/bancos-caixas" },
          ],
        },
      ],
    },
    // Fim -	•	Tesouraria

    // Menu	•	Comunicação
    {
      name: "Comunicação",
      code: "COMUNI",
      type: "dropDown",
      tooltip: "Comunicação",
      icon: "chat",
      state: "comunicacao",
      sub: [
        { name: "Newsletters", state: "newsletters" },
        { name: "SMS’s", state: "sms" },
        { name: "Contactos", state: "contactos" },
        { name: "Listas", state: "listas" },
      ],
    },
    // Fim -	•	Comunicação

    // Menu	•	Utilizadores
    {
      name: "Utilizadores",
      code: "UTILIZ",
      type: "dropDown",
      tooltip: "Material",
      icon: "person_add",
      state: "utilizadores",
      sub: [
        { name: "Utilizadores", state: "lista" },
        { name: "Perfis", state: "perfis" },
        { name: "Permissões", state: "permissoes" },
      ],
    },
    // Fim -	•	Utilizadores

    // Menu	•	Configurações
    {
      name: "Configurações",
      code: null,
      type: "dropDown",
      tooltip: "Material",
      icon: "settings_applications",
      state: "material",
      sub: [
        {
          name: "Sistema",
          type: "dropDown",
          sub: [{ name: "Dados Sistema", state: "" }],
        },
        {
          name: "Localização",
          type: "dropDown",
          sub: [
            { name: "Paises", state: "" },
            { name: "Províncias", state: "" },
            { name: "Municípios", state: "" },
            { name: "Zonas", state: "" },
            { name: "Códigos Postais", state: "" },
          ],
        },
        {
          name: "Moedas",
          type: "dropDown",
          sub: [
            { name: "Moedas", state: "" },
            { name: "Moedas Sistema", state: "" },
          ],
        },
        {
          name: "Idiomas",
          type: "dropDown",
          sub: [{ name: "Idiomas", state: "" }],
        },
        {
          name: "Documentos",
          type: "dropDown",
          sub: [{ name: "Tipos de Documentos Identificação", state: "" }],
        },
        {
          name: "Impostos",
          type: "dropDown",
          sub: [
            { name: "Retenções", state: "" },
            { name: "Impostos", state: "" },
          ],
        },
      ],
    },
    // Fim -	•	Configurações

    {
      name: "Administração",
      code: "ADMINI",
      type: "dropDown",
      tooltip: "Administração",
      icon: "keyboard_hide",
      state: "administracao",
      sub: [
        { name: "Aplicação", state: "aplicacao" },
        { name: "Empresas", state: "empresas" },
      ],
    },
  ];

  constructor() {}

  separatorMenu: IMenuItem[] = [
    {
      type: "separator",
      name: "Componentes personalizados",
    },
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
    },
    {
      type: "separator",
      name: "Componentes Integrados",
    },

    {
      type: "separator",
      name: "Other components",
    },
    {
      name: "SESSÕES",
      type: "dropDown",
      tooltip: "Páginas",
      icon: "view_carousel",
      state: "sessions",
      sub: [
        { name: "REGISTAR", state: "signup" },
        { name: "ENTRAR", state: "signin" },
        { name: "ESQUECER", state: "forgot-password" },
        { name: "LOCKSCREEN", state: "lockscreen" },
        { name: "NOTFOUND", state: "404" },
        { name: "ERRO", state: "error" },
      ],
    },
  ];

  plainMenu: IMenuItem[] = [
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
    },

    {
      name: "TOUR",
      type: "link",
      tooltip: "Tour",
      icon: "flight_takeoff",
      state: "tour",
    },

    {
      name: "SESSÃO",
      type: "dropDown",
      tooltip: "Páginas",
      icon: "view_carousel",
      state: "sessions",
      sub: [
        { name: "REGISTAR", state: "signup" },
        { name: "ENTRAR", state: "signin" },
        { name: "ESQUECER", state: "forgot-password" },
        { name: "LOCKSCREEN", state: "lockscreen" },
        { name: "NOTFOUND", state: "404" },
        { name: "ERRO", state: "error" },
      ],
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = "Recentemente acedidos";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
