import { Component, OnInit, Input } from "@angular/core";
import { NavigationService } from "../../services/navigation.service";
import { AplicacaoService } from "app/shared/services/aplicacao-service/aplicacao.service";
import { Menus } from "app/shared/models/aplicacao/menus";
import { Observable, of } from "rxjs";
import { ClientesGeraisService } from "app/shared/services/clientes-service/clientes-gerais.service";
import { Entidades } from "app/shared/models/clientes/entidades";
import { Router } from "@angular/router";
import { ArtigosClassificacoes } from "app/shared/models/artigos/artigosclassificacoes";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { catchError } from "rxjs/operators";
import { ArtigosService } from "app/shared/services/artigos/artigos.service";
import { ProcessosClassificacoes } from "app/shared/models/processos/processosclassificacoes";
import { ProcessosService } from "app/shared/services/processos/processos.service";
import {menuCod} from '../../fake.db/data';
import {MenuData} from '../../services/menu-data';
@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.template.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  @Input("items") public menuItems: any[] = [];
  @Input("hasIconMenu") public hasIconTypeMenuItem: boolean;
  @Input("iconMenuTitle") public iconTypeMenuTitle: string;
  menuData: any;

  menus$: Observable<Menus[]>;

  public listamenus: string[] = [];
  public listaMenusObservable$: Observable<string[]>;
  public clientesEntidades$: Observable<Entidades[]>;
  public processosClassificacoes$: Observable<ProcessosClassificacoes[]>;
  public artigosClassificacao$: Observable<ArtigosClassificacoes[]>;
  public idMenuActivo: number;
  listaMenu: any[];
  codigoMenu = menuCod;
  baseUrl = environment.apiURL;
  constructor(
    private sideMenu: NavigationService,
    private aplicacaoService: AplicacaoService,
    private clientesGeraisService: ClientesGeraisService,
    private artigosService: ArtigosService,
    private processosService: ProcessosService,
    private data: MenuData
  ) {}

  ngOnInit() {
    this.menus$ = this.aplicacaoService.getMenus();
    this.menus$.subscribe(res => {
      this.data.setMenuData(res[0].codigo);

      console.log(res[0].codigo);
      this.data.menuData.subscribe(re => this.menuData = re);
    });
    this.artigosClassificacao$ = this.artigosService.getClassificacoes();
    this.clientesEntidades$ = this.clientesGeraisService.getEntidades();
    this.processosClassificacoes$ = this.processosService.getClassificacoes();

    this.aplicacaoService.getMenus().subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        this.listamenus.push(res[i].codigo);
      }
    });


  }
  // ngOnInit() {}
  // Only for demo purpose
  activarDropDown(id) {
    if (this.idMenuActivo === id) {return (this.idMenuActivo = 0); }
    return (this.idMenuActivo = id);
  }
}
