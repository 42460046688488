import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { Subscription } from 'rxjs';
import {ITheme, ThemeService} from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import {CustomizerService} from '../../services/customizer.service';

// @ts-ignore
@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls : ['./header-top.component.scss']
})
export class HeaderTopComponent implements OnInit, OnDestroy {


  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];

  /*
  * Dados do menu das linguas
   */
  currentLang = 'pt';
  availableLangs = [
    {
      name: 'AO',
      code: 'pt',
    },
 ];

  @Input() notificPanel;
 
  isCustomizerOpen = false;
  
  viewMode: 'options' | 'json' = 'options';
  sidebarColors: any[];
  topbarColors: any[];


  selectedMenu: string = 'icon-menu';
  selectedLayout: string;
  isTopbarFixed = false;
  isFooterFixed = false;
  isRTL = false;
  sidenavTypes = [
    {
      name: "Menu Padrão",
      value: "default-menu"
    },
    {
      name: "Menu Separador",
      value: "separator-menu"
    },
    {
      name: "Menu Icon",
      value: "icon-menu"
    }
  ];


  perfectScrollbarEnabled: boolean = true;
  /*
  /   Construtor
  */
  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    public customizer: CustomizerService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.menuItemSub = this.navService.menuItems$
    .subscribe(res => {
      res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
      let limit = 5;
      let mainItems: any[] = res.slice(0, limit);
      if (res.length <= limit) {
        return this.menuItems = mainItems;
      }
      let subItems : any[] = res.slice(limit, res.length - 1)
      mainItems.push({
        name: 'Mais',
        type: 'dropDown',
        tooltip: 'More',
        icon: 'more_horiz',
        sub: subItems
      });
      this.menuItems = mainItems;
    });

    console.log('Selected ' +this.selectedLayout)
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }
  setLang() {
    this.translate.use(this.currentLang);
  }

  changeTheme(theme) {
    this.layout.publishLayoutChange({matTheme: theme.name})
  }

  /*
  *   Toggle Notification
  */
  toggleNotific() {
    this.notificPanel.toggle();
  }
  /*
  * Toggle Sidenav
  */
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }

    /*
    * Publish Layout Change
    */
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }// Fim do mentodo toggleSidenav

  /*
  * Fixar a barra top
   */
  toggleTopbarFixed(data) {
    this.layout.publishLayoutChange({ topbarFixed: data.checked });
  }


  /*
  *  Mudar o estilo do Layout
  */
  changeLayoutStyle(data) {
    if ( this.selectedLayout === 'top') {
        this.layout.publishLayoutChange({ navigationPos: this.selectedLayout, sidebarStyle: 'compact', sidebarCompactToggle: true });
        console.log(data.value);
    } else {
        this.layout.publishLayoutChange({ navigationPos: this.selectedLayout });
        console.log(data.value);
    }

   
  }

  /*
  *  Mudar a barra de navegacao lateral
  */
  changeSidenav(data) {
    this.navService.publishNavigationChange(data.value);
  }
  /*
    *  Mudar a barra de navegacao lateral
    */
  tooglePerfectScrollbar(data) {
    this.layout.publishLayoutChange({perfectScrollbar: this.perfectScrollbarEnabled});
  }

  toggleDir(data) {
    const dir = data.checked ? 'rtl' : 'ltr';
    this.layout.publishLayoutChange({ dir: dir });
  }

  toggleBreadcrumb(data) {
    this.layout.publishLayoutChange({ useBreadcrumb: data.checked });
  }
} // Fim da Class Component

