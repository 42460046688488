import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable(
    {providedIn: 'root'}
) export  class MenuData {
    
    dataSource  = new BehaviorSubject<any>('');
    menuData = this.dataSource.asObservable();
    constructor() {
    }

    public setMenuData(data: any) {
        this.dataSource.next(data);
    }

}
