import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erro-editar',
  templateUrl: './erro-editar.component.html',
  styleUrls: ['./erro-editar.component.scss']
})
export class ErroEditarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
