import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { ProcessosClassificacoes } from "app/shared/models/processos/processosclassificacoes";
import { catchError } from "rxjs/operators";
import { ProcessosTipos } from "app/shared/models/processos/processostipos";
import { ProcessosEstados } from "app/shared/models/processos/processosestados";
import { Processos } from "app/shared/models/processos/processos";
import { ProcessoTarefaFiltro } from "app/shared/models/processos/filtros/processotarefafiltro";
import { ProcessosTarefas } from "app/shared/models/processos/processostarefas";
import { AnimationStyleMetadata } from "@angular/animations";

const httpOptionsPost = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
  }),
};

@Injectable({
  providedIn: "root",
})
export class ProcessosService {
  baseUrl: string;
  baseUrlProcesso: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiURL}`;
    this.baseUrlProcesso = `${environment.apiURLProcessos}`;
  }

  getClassificacoes(): Observable<ProcessosClassificacoes[]> {
    return this.http
      .get(this.baseUrlProcesso + "classificacoes", httpOptionsPost)
      .pipe(catchError(null));
  }

  getClassificacao(id: number): Observable<ProcessosClassificacoes> {
    return this.http
      .get(this.baseUrlProcesso + "classificacoes/" + id, httpOptionsPost)
      .pipe(catchError(null));
  }

  getProcessos(model: any): Observable<Processos[]> {
    return this.http
      .post(
        this.baseUrlProcesso + "processos/GetPrProcessos",
        model,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }

  getTipos(idClassificacao: number): Observable<ProcessosTipos[]> {
    return this.http
      .get(this.baseUrlProcesso + "tipos/" + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }

  getTipo(id: number): Observable<ProcessosTipos> {
    return this.http
      .get(this.baseUrlProcesso + "tipos/" + id, httpOptionsPost)
      .pipe(catchError(null));
  }

  getTarefas(model: any): Observable<ProcessosTarefas[]> {
    return this.http
      .post(
        this.baseUrlProcesso + "tarefas/GetPrtarefas",
        model,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }

  getEstados(): Observable<ProcessosEstados[]> {
    return this.http
      .get(this.baseUrlProcesso + "estados/", httpOptionsPost)
      .pipe(catchError(null));
  }

  salvar(model: any) {
    return this.http
      .post(this.baseUrlProcesso + "processos/", model, httpOptionsPost)
      .pipe(catchError(null));
  }

  gerarToken(model: any) {
    return this.http
      .post(this.baseUrlProcesso + "gerartoken/", model)
      .pipe(catchError(null));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
