import { Component, OnInit, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-erro-criar',
  templateUrl: './erro-criar.component.html',
  styleUrls: ['./erro-criar.component.scss']
})
export class ErroCriarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit() {
  }

}
