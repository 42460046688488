import { Component, OnInit } from '@angular/core';
import { AplicacaoService } from 'app/shared/services/aplicacao-service/aplicacao.service';
import { Aplicacao } from 'app/shared/models/aplicacao/aplicacao';
import { Observable } from 'rxjs';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  aplicacao: Aplicacao;
  constructor(private aplicacaoService: AplicacaoService) {}

  ngOnInit() {
    this.getAplicacao();
  }

  getAplicacao() {
    this.aplicacaoService.getAplicacao().subscribe(res => {
      this.aplicacao = res;
    }, error => {
      console.log('Falha ao obter dados da aplicação');
    })
  }
}
