import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-erro-eliminar',
  templateUrl: './erro-eliminar.component.html',
  styleUrls: ['./erro-eliminar.component.scss']
})
export class ErroEliminarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit() {
  }

}
