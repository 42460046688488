import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import {PreLoadDataService} from './shared/resolver/pre-load-data.service';

export const rootRouterConfig: Routes = [
         {
           path: '',
           redirectTo: 'sessoes/login',
           pathMatch: 'full',
           data: { title: 'Dashboard', breadcrumb: 'Padrão' },
         },
         {
           path: '',
           component: AuthLayoutComponent,
           children: [
             {
               path: 'sessoes',
               loadChildren: () =>
                 import('./views/sessoes/sessions.module').then(
                   (m) => m.SessionsModule
                 ),
               data: { title: 'Sessões' },
             },
           ],
         },
         {
           path: '',
           component: AdminLayoutComponent,
           canActivate: [AuthGuard],
           children: [
             {
               path: 'dashboard',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/dashboard/dashboard.module').then(
                   (m) => m.DashboardModule
                 ),
               data: { preload: true },
             },
             {
               path: 'clientes',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/clientes/clientes.module').then(
                   (m) => m.ClientesModule
                 ),
                data: { preload: true}
             },
             {
               path: 'recrutamento',
              canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/recrutamento/recrutamento.module').then(
                   (m) => m.RecrutamentoModule
                 ),
               data: { title: 'Recrutamento', breadcrumb: 'Recrutamento' }
             },
             {
               path: 'artigos',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/artigos/artigos.module').then(
                   (m) => m.ArtigosModule
                 ),
               data: { preload: true}
             },
             {
               path: 'processos',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/processos/processos.module').then(
                   (m) => m.ProcessosModule
                 ),
               // data: { title: 'Processos', breadcrumb: 'Processos' }
             },
             {
               path: 'eventos',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/eventos/eventos.module').then(
                   (m) => m.EventosModule
                 ),
               data: { preload: false}
             },

             {
               path: 'rh',
             canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/rh/rh.module').then((m) => m.RhModule),
               data: { preload: false}
             },
             {
               path: 'facturacao',
              canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/vendas/vendas.module').then(
                   (m) => m.VendasModule
                 ),
               data: { preload: false}
             },
             {
               path: 'equipamentos',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/equipamentos/equipamentos.module').then(
                   (m) => m.EquipamentosModule
                 ),
               data: { preload: false}
             },
             {
               path: 'comunicacao',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/comunicacao/comunicacao.module').then(
                   (m) => m.ComunicacaoModule
                 ),
               data: { preload: false}
             },
             {
               path: 'tesouraria',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/tesouraria/tesouraria.module').then(
                   (m) => m.TesourariaModule
                 ),
               data: { preload: false}
             },
             {
               path: 'utilizadores',
               canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/utilizadores/utilizadores.module').then(
                   (m) => m.UtilizadoresModule
                 ),
               data: { title: 'Utilizadores', breadcrumb: 'Utilizadores' },
             },
             {
               path: 'manutencao',
              canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/manutencao/manutencao.module').then(
                   (m) => m.ManutencaoModule
                 ),
               data: { preload: false}
             },
             {
               path: 'administracao',
              canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/administracao/administracao.module').then(
                   (m) => m.AdministracaoModule
                 ),
               data: { title: 'Administração', breadcrumb: 'Administração' },
             },
             {
               path: 'empresas',
              canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/administracao/administracao.module').then(
                   (m) => m.AdministracaoModule
                 ),
               data: { title: 'Empresas', breadcrumb: 'Empresas' },
             },
             {
               path: 'perfil',
              canActivate: [AuthGuard],
               loadChildren: () =>
                 import('./views/perfil/perfil.module').then(
                   (m) => m.PerfilModule
                 ),
               data: { title: 'Perfil', breadcrumb: 'Perfil' },
             },

             // MODULO PARA TESTES SIMPLES ANTES DA IMPLEMENTACAO //
           ],
         },
       ];

