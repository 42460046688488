import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material';
import { MismatchDirective } from './shared/directives/mismatch.directive';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { registerLocaleData, DatePipe } from '@angular/common';
import { LoginService } from './shared/services/aplicacao-service/login.service';
import {
  ErrorInterceptor,
  ErrorInterceptorProvider
} from './shared/services/aplicacao-service/error.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './shared/services/auth/auth.guard';

registerLocaleData(localePt);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        // whitelistedDomains: ["demo.kiami.ao"]
        // blacklistedRoutes: ["demo.kiami.ao/v1/login"]
        whitelistedDomains: ["localhost:4200"],
        blacklistedRoutes: ["localhost:4200/v1/login"],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    RouterModule.forRoot(rootRouterConfig, {
      useHash: true,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules
    }),
  ],
  declarations: [AppComponent, MismatchDirective],
  providers: [
    LoginService,
    DatePipe,
    ErrorInterceptorProvider,
    AuthGuard,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: LOCALE_ID, useValue: 'pt' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
