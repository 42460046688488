import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Entidades } from "app/shared/models/clientes/entidades";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Titulos } from "app/shared/models/aplicacao/titulos";
import { EstadosClientes } from "app/shared/models/aplicacao/estadosclientes";
import { TiposEnderecoCliente } from "app/shared/models/clientes/tiposenderecoCliente";
import { environment } from "environments/environment";
import { Tipos } from "app/shared/models/clientes/tipos";
import { TiposArquivos } from "app/shared/models/clientes/tiposarquivos";
import { TiposDocumentos } from "app/shared/models/clientes/tiposdocumentos";
import { ClienteAcesso } from "app/shared/models/clientes/clienteacessos";
import { Codigo } from "app/shared/models/clientes/codigo";

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
  }),
};

@Injectable({
  providedIn: "root",
})
export class ClientesGeraisService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiURL}`;
  }

  getEntidades(): Observable<Entidades[]> {
    return this.http
      .get(this.baseUrl + "entidades", httpOptions)
      .pipe(catchError(null));
  }

  getEntidade(id: number): Observable<Entidades> {
    return this.http
      .get(this.baseUrl + "entidades/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getTitulos(): Observable<Titulos[]> {
    return this.http
      .get(this.baseUrl + "titulos", httpOptions)
      .pipe(catchError(null));
  }

  getAcessos(id: number): Observable<ClienteAcesso[]> {
    return this.http
      .get(this.baseUrl + "clientes/acessos/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getAcessosCliente(id: number): Observable<ClienteAcesso[]> {
    return this.http
      .get(this.baseUrl + "clientes/acessosclientes/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getAcesso(id: number, idCliente: number): Observable<ClienteAcesso> {
    return this.http
      .get(
        this.baseUrl + "clientes/acessos/" + id + "/" + idCliente,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getEstados(id: number): Observable<EstadosClientes[]> {
    return this.http
      .get(this.baseUrl + "clientes/estados/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getEstado(id: number): Observable<EstadosClientes> {
    return this.http
      .get(this.baseUrl + "clientes/estadoprincipal/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getTipos(id: number): Observable<Tipos[]> {
    return this.http
      .get(this.baseUrl + "clientes/tipos/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getTiposDocumentos(id: number): Observable<TiposDocumentos[]> {
    return this.http
      .get(this.baseUrl + "tiposdocumentos/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getTipoDocumento(
    id: number,
    idEntidade: number
  ): Observable<TiposDocumentos> {
    return this.http
      .get(
        this.baseUrl + "tiposdocumentos/" + id + "/" + idEntidade,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getTiposArquivos(id: number): Observable<TiposArquivos[]> {
    return this.http
      .get(this.baseUrl + "tiposArquivos/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getTipoArquivo(id: number): Observable<TiposArquivos> {
    return this.http
      .get(this.baseUrl + "tipoArquivo/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getTiposEnderecos(id: number): Observable<TiposEnderecoCliente[]> {
    return this.http
      .get(this.baseUrl + "tiposenderecos/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getTipoEndereco(id: number): Observable<TiposEnderecoCliente> {
    return this.http
      .get(this.baseUrl + "tipoendereco/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getCodigo(idEntidade: number, idTipo:number): Observable<Codigo> {
    return this.http
      .get(
        this.baseUrl + "clientes/codigos/" + idEntidade + "/" + idTipo,
        httpOptions
      )
      .pipe(catchError(null));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  /*Post*/
  salvarEstado(model: any) {
    return this.http
      .post(this.baseUrl + "estadosclientes/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }
  removerEstadoEntidade(model: any) {
    return this.http
      .post(this.baseUrl + "remover/estadosclientes/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  salvarTipos(model: any) {
    return this.http
      .post(this.baseUrl + "clientes/tipos/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  removerTipos(model: any) {
    return this.http
      .post(this.baseUrl + "clientes/remover/tipos/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  salvarTiposArquivos(model: any) {
    return this.http
      .post(this.baseUrl + "tiposarquivos/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  removerTiposArquivos(model: any) {
    return this.http
      .post(this.baseUrl + "remover/tiposarquivos/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  salvarAcessos(model: any) {
    return this.http
      .post(this.baseUrl + "clientes/acessos/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  apagarAcesso(model: any) {
    return this.http
      .post(this.baseUrl + "clientes/acessos/remover/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  editarAcesso(model: any) {
    return this.http
      .post(this.baseUrl + "clientes/acessos/editar/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  editarPasswordAcesso(model: any) {
    return this.http
      .post(
        this.baseUrl + "clientes/acessos/editarpassword/",
        model,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }
}
