import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  Renderer2
} from "@angular/core";
import { ITheme, ThemeService } from "../../services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "../../services/navigation.service";
import { CustomizerService } from "../../services/customizer.service";
import { Router } from "@angular/router";
import { LoginService } from "app/shared/services/aplicacao-service/login.service";
import { UtilizadoresService } from "app/shared/services/aplicacao-service/utilizadores.service";
import { Utilizadores } from "app/shared/models/aplicacao/utilizadores";
import { Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-header-side",
  templateUrl: "./header-side.template.html",
  styleUrls: ["./header-side.component.scss"]
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;

  @Input() isCustomizerOpen: boolean;

  viewMode: "options" | "json" = "options";
  sidenavTypes = [
    {
      name: "Menu Padrão",
      value: "default-menu"
    },
    {
      name: "Menu Separador",
      value: "separator-menu"
    },
    {
      name: "Menu Icon",
      value: "icon-menu"
    }
  ];
  sidebarColors: any[];
  topbarColors: any[];

  layoutConf;
  selectedMenu: string = "icon-menu";
  selectedLayout: string;
  isTopbarFixed = false;
  isFooterFixed = false;
  isRTL = false;
  egretThemes: ITheme[];
  perfectScrollbarEnabled: boolean = true;
  fotografia?: string;

  public availableLangs = [
    { name: "AO", code: "pt", flag: "flag-icon-es" },
    { name: "EN", code: "en", flag: "flag-icon-us" },
    { name: "ES", code: "es", flag: "flag-icon-es" }
  ];

  currentLang = this.availableLangs[0];

  constructor(
    private navService: NavigationService,
    public customizer: CustomizerService,
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public loginService: LoginService,
    private utilizadoresService: UtilizadoresService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
    this.selectedLayout = this.layoutConf.navigationPos;
    this.isTopbarFixed = this.layoutConf.topbarFixed;
    this.isRTL = this.layoutConf.dir === "rtl";
    this.egretThemes = this.themeService.egretThemes;
    this.getImagemUtilizador(this.loginService.decodedToken.nameid);
  }

  getImagemUtilizador(id: number) {
    this.utilizadoresService.getUtilizador(id).subscribe(res => {
      this.fotografia = res.fotografia;
    });
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full"
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed"
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === "compact") {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: "full",
          sidebarCompactToggle: false
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: "compact",
        sidebarCompactToggle: true
      },
      { transitionClass: true }
    );
  }

  onSearch(e) {
    //   console.log(e)
  }

  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
    this.layout.publishLayoutChange({ matTheme: theme.name });
  }
  changeLayoutStyle(data) {
    this.layout.publishLayoutChange({ navigationPos: this.selectedLayout });
  }
  changeSidenav(data) {
    this.navService.publishNavigationChange(data.value);
  }
  toggleBreadcrumb(data) {
    this.layout.publishLayoutChange({ useBreadcrumb: data.checked });
  }
  toggleTopbarFixed(data) {
    this.layout.publishLayoutChange({ topbarFixed: data.checked });
  }
  toggleDir(data) {
    const dir = data.checked ? "rtl" : "ltr";
    this.layout.publishLayoutChange({ dir: dir });
  }
  tooglePerfectScrollbar(data) {
    this.layout.publishLayoutChange({
      perfectScrollbar: this.perfectScrollbarEnabled
    });
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(["sessoes/login"]);
  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  
}
