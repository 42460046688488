import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '../aplicacao-service/login.service';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically

  constructor(private router: Router, private loginService: LoginService) {}
  canActivate(): boolean {
    if (this.loginService.loggedIn()) {
      return true;
    }
    this.router.navigate(['/sessoes/login']);
    return false;
  }
}
