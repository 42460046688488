import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import {catchError, share} from 'rxjs/operators';
import { Utilizadores } from "app/shared/models/aplicacao/utilizadores";
import { UtilizadoresEstados } from "app/shared/models/aplicacao/utilizadoresestados";
import { environment } from "environments/environment";
import { MensagemDto } from "app/shared/models/aplicacao/mensagemdto";
import { Perfis } from "app/shared/models/aplicacao/perfis";


const httpOptions = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
  }),
};
const httpOptionsPost = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
  }),
};

@Injectable({
  providedIn: "root",
})
export class UtilizadoresService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiURL}`;
  }

  getUtilizadores(): Observable<Utilizadores[]> {
    return this.http
      .get(this.baseUrl + "utilizadores", httpOptions)
      .pipe(catchError(null), share());
  }

  getPerfis(): Observable<Perfis[]> {
    return this.http
      .get(this.baseUrl + "utilizadores/perfis", httpOptions)
      .pipe(catchError(null), share());
  }

  getUtilizadoresEstados(): Observable<UtilizadoresEstados[]> {
    return this.http
      .get(this.baseUrl + "utilizadores/estados", httpOptions)
      .pipe(catchError(null), share());
  }

  getUtilizador(id: number): Observable<Utilizadores> {
    return this.http
      .get(this.baseUrl + "utilizadores/" + id, httpOptions)
      .pipe(catchError(this.handleError));
  }

  removerUtilizador(model: any) {
    return this.http
      .post(this.baseUrl + "utilizadores/remover/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  alterarPassword(model: any): Observable<MensagemDto> {
    return this.http
      .post(this.baseUrl + "utilizadores/alterarpassword/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  salvarUtilizador(model: any) {
    return this.http
      .post(this.baseUrl + "utilizadores/", model, httpOptionsPost)
      .pipe(catchError(this.handleError));
  }

  editarUtilizador(model: any) {
    return this.http
      .post(this.baseUrl + "utilizadores/editar/", model, httpOptionsPost)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
