export class FiltrosClientes {
  constructor(
    public IdEntidade?: number,
    public CodigoFiltro?: number,
    public Codigo?: string,
    public Email?: string,
    public Nome?: string,
    public NIF?: string,
    public IdTipos?: number[],
    public IdEstados?: number[]
  ) {}
}
