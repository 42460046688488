import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[appVerSenha]'
})
export class VerSenhaDirective implements  OnInit {
toggleFlag = false;
  constructor(private renderer: Renderer2, private elRef: ElementRef) { }

  ngOnInit(): void {
     const inputField = this.elRef.nativeElement;
     this.renderer.listen(inputField, 'click', () => {
        this.toggleFlag = !this.toggleFlag;
        if (this.toggleFlag) {
          this.renderer.setAttribute(inputField, 'type', 'text');
        }else {
          this.renderer.setAttribute(inputField, 'type', 'password');
        }
     });
  }

}
