import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { ArtigosClassificacoes } from "app/shared/models/artigos/artigosclassificacoes";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";
import { ArtigosEstados } from "app/shared/models/artigos/artigosestados";
import { ArtigosTipos } from "app/shared/models/artigos/artigostipos";
import { CodigosIVA } from "app/shared/models/artigos/codigosiva";
import { OpccoesValidade } from "app/shared/models/artigos/opccoesvalidade";
import { identifierModuleUrl } from "@angular/compiler";
import { Unidades } from "app/shared/models/aplicacao/unidades";
import { Artigos } from "app/shared/models/artigos/artigos";

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
  }),
};
const httpOptionsPost = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
  }),
};

@Injectable({
  providedIn: "root",
})
export class ArtigosService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiURL}`;
  }

  getClassificacoes(): Observable<ArtigosClassificacoes[]> {
    return this.http
      .get(this.baseUrl + "artigos/classificacoes", httpOptions)
      .pipe(catchError(null));
  }

  getClassificacao(id: number): Observable<ArtigosClassificacoes> {
    return this.http
      .get(this.baseUrl + "artigos/classificacoes/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getEstados(idClassificacao: number): Observable<ArtigosEstados[]> {
    return this.http
      .get(this.baseUrl + "artigos/estados/" + idClassificacao, httpOptions)
      .pipe(catchError(null));
  }

  getTipos(
    idClassificacao: number,
    idIdioma: number
  ): Observable<ArtigosTipos[]> {
    return this.http
      .get(
        this.baseUrl + "artigos/tipos/" + idClassificacao + "/" + idIdioma,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getCategorias(
    idClassificacao: number,
    idIdioma: number
  ): Observable<ArtigosTipos[]> {
    return this.http
      .get(
        this.baseUrl + "artigos/categorias/" + idClassificacao + "/" + idIdioma,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getOpcoesValidade(): Observable<OpccoesValidade[]> {
    return this.http
      .get(this.baseUrl + "artigos/opccoesvalidade", httpOptions)
      .pipe(catchError(null));
  }

  getArtigo(id: number): Observable<Artigos> {
    return this.http
      .get(this.baseUrl + "artigos/artigo/" + id, httpOptions)
      .pipe(catchError(null));
  }

  getArtigos(idClassificacao: number): Observable<Artigos[]> {
    return this.http
      .get(this.baseUrl + "artigos/" + idClassificacao, httpOptions)
      .pipe(catchError(null));
  }

  getTodosArtigos(): Observable<Artigos[]> {
    return this.http
      .get(this.baseUrl + "artigos/todosartigos", httpOptions)
      .pipe(catchError(null));
  }

  /*Salvar*/
  salvarCategoria(model: any) {
    return this.http
      .post(this.baseUrl + "artigos/categorias/", model, httpOptions)
      .pipe(catchError(null));
  }

  salvarTipo(model: any) {
    return this.http
      .post(this.baseUrl + "artigos/tipo/", model, httpOptions)
      .pipe(catchError(null));
  }

  salvaArtigo(model: any) {
    return this.http
      .post(this.baseUrl + "artigos/", model, httpOptionsPost)
      .pipe(catchError(null));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
