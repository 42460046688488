import {Fase} from '../models/processos/fase';
import { ProcessosTarefas } from '../models/processos/processostarefas';
import {FiltrosClientes} from '../models/clientes/filtros/filtroclientes';

export const lista: number[] = new Array();

export const tarefas: ProcessosTarefas[] = [
    {
         idTarefa: 1,
         ordem: 1,
         nome: ' Recrutamento e Seleção (R&S)',
         idClassificacao: 2,
         codTarefa: 'COD2345',
         descricao: 'Descricao da tarefas',
         idTipoTarefa: 2,
         dataFim: '',
         dataInicio: '',
         duracao: 23,
         idUnidadeTempo: 2,
         idOpcaoFicheiro: 2,
         idOpcaoObservacoes: 1
    },
    {
        idTarefa: 2,
        ordem: 2,
        nome: 'Integração de recém-contratados',
        idClassificacao: 2,
        codTarefa: 'COD2345',
        descricao: 'Descricao da tarefas',
        idTipoTarefa: 2,
        duracao: 23,
        idUnidadeTempo: 2,
        idOpcaoFicheiro: 2,
        idOpcaoObservacoes: 2
    },
    {
        idTarefa: 3,
        ordem: 3,
        nome: 'Avaliação de desempenho',
        idClassificacao: 2,
        codTarefa: 'COD2345',
        descricao: 'Descricao da tarefas',
        idTipoTarefa: 2,
        duracao: 23,
        idUnidadeTempo: 2,
        idOpcaoFicheiro: 2,
        idOpcaoObservacoes: 2
    },
    {
        idTarefa: 4,
        ordem: 4,
        nome: 'Treinamento e desenvolvimento',
        idClassificacao: 2,
        codTarefa: 'COD2345',
        descricao: 'Descricao da tarefas',
        idTipoTarefa: 2,
        duracao: 23,
        idUnidadeTempo: 2,
        idOpcaoFicheiro: 2,
        idOpcaoObservacoes: 3
    },
];

export const menuCod = [
    {
        codMenu: "CLIENT",
        RotaAPI: "",
        menu: "Clientes",
    },
    {
        codMenu: "RECHUM",
        RotaAPI: "",
        menu: "Recursos Humanos",
    },

    {
        codMenu: "ARTIGO",
        RotaAPI: "",
        menu: "Artigos",
    },
    {
        codMenu: "PROCES",
        RotaAPI: "",
        menu: "Processos",
    },

    {
        codMenu: "VENDAS",
        RotaAPI: "",
        menu: "Vendas",
    },

    {
        codMenu: "EVENTO",
        RotaAPI: "",
        menu: "Eventos",
    },

    {
        codMenu: "COMUNI",
        RotaAPI: "",
        menu: "Comunicação",
    },
    {
        codMenu: "ADMINI",
        RotaAPI: "",
        menu: "Administração",
    },
    {
        codMenu: "UTILIZ",
        RotaAPI: "",
        menu: "Utilizadores",
    },
    {
        codMenu: "EQUIPA",
        RotaAPI: "",
        menu: "Equipamentos",
    },
];

export const model = new FiltrosClientes(1, 1, "", "", "", "", lista, lista);

